import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorJwt implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // // add auth header with jwt if user is logged in and request is to the api url
    // const currentUser = this.authenticationService.currentUserValue;
    // const isLoggedIn = currentUser && currentUser.token;
    // // console.log('Current User: ' + currentUser);
    // // console.log('isLoggedIn: ' + isLoggedIn);
    // // console.log('Current User Token:' + currentUser.token);
    // const isApiUrl = request.url.startsWith(environment.URL_SER_NODE);
    // if (isLoggedIn && isApiUrl) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `${currentUser.token}`
    //     }
    //   });
    // }
    // return next.handle(request);
    return next.handle(req);
  }

}

