<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body text-center">
                    <h2>Bienvenido a Q-bits:<h1 class="d-inline">Guestbook</h1>
                    </h2>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid text-center">
    <div class="row row justify-content-center">
        <div class="col-4 col-sm-12 d-flex justify-content-center">
            <a routerLink="/start">
                <div class="card ripple" style="width: 18rem;">
                    <div class="card-body">
                        <h2><i class="bi bi-nut"></i></h2>
                        <h5 class="card-title">Configuración</h5>
                        <p class="card-text">Accede a esta sección para cambiar la información de tu establecimiento.</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>

<hr>

<div class="container text-center">
    <div class="row">
        <div class="col">
            <h5 class="lead">REPORTES:</h5>
        </div>
    </div>
</div>

<hr>

<div class="container-fluid text-center">

    <div class="row">
        <div class="col">
            <a routerLink="/reports">
                <div class="card ripple" >
                    <div class="card-body">
                        <h2><i class="bi bi-calendar-event"></i></h2>
                        <h5 class="card-title">Reservaciones</h5>
                        <p class="card-text">Accede a esta sección para revisar el estado de las reservaciones en un reporte general con búsquedas dinámicas.</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a routerLink="/reports/payments">
                <div class="card ripple">
                    <div class="card-body">
                        <h2><i class="bi bi-receipt"></i></h2>
                        <h5 class="card-title">Pagos</h5>
                        <p class="card-text">Accede a esta sección para revisar el estado de los pagos en un reporte general con búsquedas dinámicas.</p>
                    </div>
                </div>
            </a>
        </div>
        <!-- <div class="col">
            <a routerLink="/reports/products-services">
                <div class="card ripple">
                    <div class="card-body">
                        <h2><i class="bi bi-box"></i></h2>
                        <h5 class="card-title">Productos y servicios</h5>
                        <p class="card-text">Accede a esta sección para revisar el estado de los productos y servicios en un reporte general con búsquedas dinámicas.</p>
                    </div>
                </div>
            </a>
        </div> -->
        <div class="col">
            <a routerLink="/reports/client-services">
                <div class="card ripple">
                    <div class="card-body">
                        <h2><i class="bi bi-person"></i></h2>
                        <h5 class="card-title">Servicios por cliente</h5>
                        <p class="card-text">Accede a esta sección para revisar el estado de los servicios por cliente en un reporte general con búsquedas dinámicas.</p>
                    </div>
                </div>
            </a>
        </div>
        <div class="col">
            <a routerLink="/reports/employee-services">
                <div class="card ripple">
                    <div class="card-body">
                        <h2><i class="bi bi-person-badge"></i></h2>
                        <h5 class="card-title">Servicios por empleado</h5>
                        <p class="card-text">Accede a esta sección para revisar el estado de los servicios por empleado en un reporte general con búsquedas dinámicas.</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</div>

<script>
    $(document).ready(function () {
        // Add ripple effect to buttons with .ripple class
        $(".ripple").on("mousemove", function (e) {
            var parentOffset = $(this).offset();
            var relX = e.pageX - parentOffset.left;
            var relY = e.pageY - parentOffset.top;
            $(this).find("before").css({
                top: relY,
                left: relX
            });
        });
    });
</script>