<div class="wrapper">
  <!-- Content -->
  <div id="content">

    <!-- Navbar -->
    <app-navbar></app-navbar>

    <!-- Route -->
    <router-outlet></router-outlet>
  </div>
</div>
