import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { from } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  username: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    from(Auth.currentAuthenticatedUser()).subscribe(user => {
      this.username = user.attributes.name
    })
  }

  signOut(): void {
    Auth.signOut()
    this.router.navigate(["/login"]);
  }

}
