import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Auth } from 'aws-amplify';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment.prod';

if (environment.production) {
  enableProdMode();
}

Auth.configure({ Auth: environment.cognito });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
