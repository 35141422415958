export const environment = {
  production: true,
  URL_RESERVATIONS: 'https://reservations.qbitserp.com/',
  URL_INVENTORY_UI:'https://inventorydashboard.qbitserp.com/start?warehouseId=PLACEID',
  APP_ID: 'c7d9488a-05d3-480f-8a63-9304521ff655',
  cognito: {
    userPoolId: 'us-east-1_DqDmKLteK',
    userPoolWebClientId: '5161nidfhf3ukfgoolbr2rknip',
    userPoolRegion: 'us-east-1'
  }
};
