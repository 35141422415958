<div class="container-fluid">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
        <div class="start">
            <a class="navbar-brand" routerLink="/landing">
                <img src="assets/images/q-qbits.png" width="40" height="40" alt="">
            </a>
            <a class="navbar-brand" routerLink="/landing">INICIO</a>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
            aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/reports">Reservaciones</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle purple" href="#" role="button" data-toggle="dropdown"
                        aria-expanded="false">Reportes
                    </a>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" routerLink="/reports/payments">Pagos</a>
                        <!-- <a class="dropdown-item" routerLink="/reports/products&services">Productos y servicios</a> -->
                        <a class="dropdown-item" routerLink="/reports/client-services">Servicios por cliente</a>
                        <a class="dropdown-item" routerLink="/reports/employee-services">Servicios por empleado</a>
                        <a class="dropdown-item" routerLink="/reports/client-services-detailed">Servicios por cliente (Detallado)</a>
                        <a class="dropdown-item" routerLink="/reports/employee-services-detailed">Servicios por empleado (Detallado)</a>
                        <a class="dropdown-item" routerLink="/reports/elastic">Reportes Elasticsearch</a>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/inventory">Inventario</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/start">Configuración</a>
                </li>

            </ul>
            <span class="navbar-text mr-5">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle purple" href="#" role="button" data-toggle="dropdown"
                        aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                            class="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                            <path fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                        </svg>
                    </a>
                    <div class="dropdown-menu">
                        <h5> <a class="dropdown-item">{{username}}</a></h5>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"><button class="btn btn-outline-danger" (click)="signOut()">Log
                                out</button></a>
                    </div>

                </li>
            </span>
        </div>
    </nav>
</div>