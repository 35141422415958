import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-initial',
  templateUrl: './layout-initial.component.html',
  styleUrls: ['./layout-initial.component.scss']
})
export class LayoutInitialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
}
