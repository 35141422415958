import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LayoutInitialComponent } from './layout/layout-initial/layout-initial.component';

const routes: Routes = [
  { path: '', redirectTo: '/reports', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'start',
    component: LayoutInitialComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/start/start.module').then((m) => m.StartModule),
      }
    ]
  },
  {
    path: 'reports',
    component: LayoutInitialComponent,
    canActivate: [AuthGuard], 
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReservationsModule),
      },
      {
        path: 'scenarios',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ScenariosModule),
      },
      {
        path: 'payments',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.PaymentsModule),
      }
    ]
  },
  // {
  //   path: 'payments',
  //   component: LayoutInitialComponent,
  //   canActivate: [AuthGuard], 
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./pages/reports/payments/payments.module').then((m) => m.PaymentsModule),
  //     }
  //   ]
  // },
  {
    path: 'landing',
    component: LayoutInitialComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/landing-page/landing-page.module').then((m) => m.LandingPageModule),
      }
    ]
  },
  {
    path: 'inventory',
    component: LayoutInitialComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/inventory/inventory.module').then((m) => m.InventoryModule),
      }
    ]
  },
  { path: '**', redirectTo: 'landing', pathMatch: 'full' },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

