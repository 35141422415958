import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return (Auth.currentSession()
      .then(user => {
        console.log(Auth.currentSession())
        return user?.isValid();
      }).catch(() => false)).then(authenticated => {
        if (!authenticated) {
          this.router.navigate(["/login"]);
        }
        return authenticated;
      })

  }
}
